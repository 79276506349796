import 'konva';
import m from 'mithril';
import * as bootstrap from 'bootstrap';
import feather from 'feather-icons';

feather.replace();

document.getElementById("year").innerHTML = (new Date()).getFullYear();

Konva.pixelRatio = 1; // fixes misaligned pixelation in Webkit

let benchmark = function (func, name) {
  const t0 = performance.now();
  func();
  const t1 = performance.now();
  console.log(`${name} took ${t1 - t0} milliseconds.`);
}

let GridTypeEnum = {
  SQUARE: 1,
  POINTY_HEX: 2,
  FLAT_HEX: 3
}

let ScaleModeEnum = {
  TRUE: 1,
}

let GridLibrary = {
  _getColumnLabel: function (idx) {
    let remainder = idx;
    let label = "";
    let modulo;
    while (remainder > 0) {
      modulo = (remainder - 1) % 26;
      label = String.fromCharCode(65 + modulo).toString() + label;
      remainder = parseInt((remainder - modulo) / 26);
    }
    return label;
  },
  generateSquareGrid: function (x_min, y_min, x_max, y_max, g_size) {
    let g_width = x_max - x_min;
    let g_height = y_max - y_min;
    let n_cols = ~~(g_width / g_size) + 1; // integer division: https://stackoverflow.com/a/17218003
    let xs = [...Array(n_cols).keys()]; // generate range: https://stackoverflow.com/a/36095705
    let v_lines = xs.map(function (x) { return [g_size * x, 0, g_size * x, y_max]; });
    let n_rows = ~~(g_height / g_size) + 1;
    let ys = [...Array(n_rows).keys()];
    let h_lines = ys.map(function (y) { return [0, g_size * y, x_max, g_size * y]; });
    return [...v_lines, ...h_lines].map(function (el) { return { x1: el[0], y1: el[1], x2: el[2], y2: el[3] } });
  },
  generateSquareGridLabels: function (x_min, y_min, x_max, y_max, g_size) {
    let g_width = x_max - x_min;
    let g_height = y_max - y_min;
    let n_cols = ~~(g_width / g_size) + 1; // integer division: https://stackoverflow.com/a/17218003
    let xs = [...Array(n_cols).keys()]; // generate range: https://stackoverflow.com/a/36095705
    let col_labels = xs.map(function (x) { return { label: GridLibrary._getColumnLabel(x + 1), x: x * g_size, y: g_size * 0.1 }; });
    let n_rows = ~~(g_height / g_size) + 1;
    let ys = [...Array(n_rows).keys()];
    let row_labels = ys.map(function (y) { return { label: y + 1, x: 0, y: y * g_size - (g_size * -0.5) }; });
    return [...col_labels, ...row_labels];
  },
  generateFlatHexGrid: function (x_min, y_min, x_max, y_max, g_size) {
    let D2R = Math.PI / 180;
    let flatheight = 0.5 * g_size * Math.tan(D2R * 60);
    let g_width = x_max - x_min;
    let g_height = y_max - y_min;
    let n_cols = ~~(g_width / g_size) + 1; // integer division: https://stackoverflow.com/a/17218003
    let xs = [...Array(n_cols).keys()]; // generate range: https://stackoverflow.com/a/36095705
    let n_rows = ~~(g_height / flatheight) + 1;
    let ys = [...Array(n_rows).keys()];
    let midpoints = xs.flatMap(function (x) {
      return ys.flatMap(function (y) {
        let zebra = y % 2 == 1;
        let x_offset = x * g_size * 3 + (zebra ? 1.5 * g_size : 0);
        let y_offset = y * flatheight * 2 - y * flatheight;
        return { x: x_offset, y: y_offset };
      })
    });
    let lines = midpoints.flatMap(function (point) {
      let a = { x: point.x + g_size * Math.cos(D2R * 0), y: point.y + g_size * Math.sin(D2R * 0) };
      let b = { x: point.x + g_size * Math.cos(D2R * 60), y: point.y + g_size * Math.sin(D2R * 60) };
      let c = { x: point.x + g_size * Math.cos(D2R * 120), y: point.y + g_size * Math.sin(D2R * 120) };
      let d = { x: point.x + g_size * Math.cos(D2R * 180), y: point.y + g_size * Math.sin(D2R * 180) };
      let e = { x: point.x + g_size * Math.cos(D2R * 240), y: point.y + g_size * Math.sin(D2R * 240) };
      let f = { x: point.x + g_size * Math.cos(D2R * 300), y: point.y + g_size * Math.sin(D2R * 300) };
      return [
        { x1: a.x, y1: a.y, x2: b.x, y2: b.y },
        { x1: b.x, y1: b.y, x2: c.x, y2: c.y },
        { x1: c.x, y1: c.y, x2: d.x, y2: d.y },
        { x1: d.x, y1: d.y, x2: e.x, y2: e.y },
        { x1: e.x, y1: e.y, x2: f.x, y2: f.y },
        { x1: f.x, y1: f.y, x2: a.x, y2: a.y },
      ];
    });
    return lines;
  },
  generateFlatHexGridLabels: function (x_min, y_min, x_max, y_max, g_size) {
    let g_width = x_max - x_min;
    let g_height = y_max - y_min;
    let n_cols = ~~(g_width / g_size) + 1; // integer division: https://stackoverflow.com/a/17218003
    let xs = [...Array(n_cols).keys()]; // generate range: https://stackoverflow.com/a/36095705
    let zebra = true;
    let hexh = (Math.sqrt(3) * g_size);
    let col_labels = xs.map(function (x) { zebra = !zebra; return { label: GridLibrary._getColumnLabel(x + 1), x: g_size + (x * 1.5 * g_size), y: 0.4 * g_size + (zebra ? g_size : 0) }; });
    let n_rows = ~~(g_height / g_size) + 1;
    let ys = [...Array(n_rows).keys()];
    let row_labels = ys.map(function (y) { return { label: y + 1, x: g_size, y: y * hexh + g_size }; });
    return [...col_labels, ...row_labels];
  },
  generatePointyHexGrid: function(x_min, y_min, x_max, y_max, g_size) {
    let D2R = Math.PI / 180;
    let flatheight = 0.5 * g_size * Math.tan(D2R * 60);
    let g_width = x_max - x_min;
    let g_height = y_max - y_min;
    let n_cols = ~~(g_width / flatheight) + 1; // integer division: https://stackoverflow.com/a/17218003
    let xs = [...Array(n_cols).keys()]; // generate range: https://stackoverflow.com/a/36095705
    let n_rows = ~~(g_height / g_size) + 1;
    let ys = [...Array(n_rows).keys()];
    let midpoints = xs.flatMap(function (x) {
      return ys.flatMap(function (y) {
        let zebra = y % 2 == 1;
        let x_offset = x * flatheight * 2 + (zebra ? 1 * flatheight : 0);
        let y_offset = y * (g_size + 0.5 * g_size);
        return { x: x_offset, y: y_offset };
      })
    });
    let lines = midpoints.flatMap(function (point) {
      let a = { x: point.x + g_size * Math.cos(D2R * 30), y: point.y + g_size * Math.sin(D2R * 30) };
      let b = { x: point.x + g_size * Math.cos(D2R * 90), y: point.y + g_size * Math.sin(D2R * 90) };
      let c = { x: point.x + g_size * Math.cos(D2R * 150), y: point.y + g_size * Math.sin(D2R * 150) };
      let d = { x: point.x + g_size * Math.cos(D2R * 210), y: point.y + g_size * Math.sin(D2R * 210) };
      let e = { x: point.x + g_size * Math.cos(D2R * 270), y: point.y + g_size * Math.sin(D2R * 270) };
      let f = { x: point.x + g_size * Math.cos(D2R * 330), y: point.y + g_size * Math.sin(D2R * 330) };
      return [
        { x1: a.x, y1: a.y, x2: b.x, y2: b.y },
        { x1: b.x, y1: b.y, x2: c.x, y2: c.y },
        { x1: c.x, y1: c.y, x2: d.x, y2: d.y },
        { x1: d.x, y1: d.y, x2: e.x, y2: e.y },
        { x1: e.x, y1: e.y, x2: f.x, y2: f.y },
        { x1: f.x, y1: f.y, x2: a.x, y2: a.y },
      ];
    });
    return lines;
  },
  generatePointyHexGridLabels: function (x_min, y_min, x_max, y_max, g_size) {
    let g_width = x_max - x_min;
    let g_height = y_max - y_min;
    let n_cols = ~~(g_width / g_size) + 1; // integer division: https://stackoverflow.com/a/17218003
    let xs = [...Array(n_cols).keys()]; // generate range: https://stackoverflow.com/a/36095705
    let hexw = (Math.sqrt(3) * g_size);
    let col_labels = xs.map(function (x) { return { label: GridLibrary._getColumnLabel(x + 1), x: x * hexw + (hexw - g_size)/2, y: g_size } });
    let n_rows = ~~(g_height / g_size) + 1;
    let ys = [...Array(n_rows).keys()];
    let zebra = true;
    let row_labels = ys.map(function (y) { zebra = !zebra; return { label: y + 1, x: (hexw - g_size)/2 + (zebra ? hexw / 2 : 0), y: 1.6 * g_size + (y * 1.5 * g_size) } });
    return [...col_labels, ...row_labels];
  },
}

// See: https://kevinfiol.com/blog/simple-state-management-in-mithriljs/
const State = () => ({
  grid: {
    enabled: true,
    labels: true,
    type: GridTypeEnum.SQUARE,
    size: 50,
    strokeWidth: 2,
    color: 'black',
  },
  image: {
    image: null,
    scale: 1,
    x: 0,
    y: 0,
    title: 'image',
  },
  canvas: {
    stage: null,
    imageLayer: null,
    gridLayer: null,
    width:  document.documentElement.clientWidth > 576 ? 9*50 : 3*50,
    height: document.documentElement.clientWidth > 576 ? 9*50 : 3*50,
  },
  mosaic: false
});

const Actions = state => ({
  setGridEnabled: (val) => state.grid.enabled = val,
  setLabelsEnabled: (val) => state.grid.labels = val,
  setGridType: (val) => {
    if(val != GridTypeEnum.SQUARE)
    {
      state.mosaic = false;
    }
    state.grid.type = val;
  },
  setImage: function (value) {
    state.image.image = value;
  },
  setTitle: function (value) {
    state.image.title = value.replace(/\.[^/\\.]+$/, "");
  },
  setMosaicEnabled: (val) => state.mosaic = val,

  clearByType: function(t)
  {
    var objs = state.canvas.stage.find(t);
    objs.map(o => o.destroy());
  },
  renderImage: function () {
    if (state.image.image != null) {
      state.canvas.imageLayer.add(new Konva.Image({
        x: state.image.x,
        y: state.image.y,
        image: state.image.image,
        width: state.image.image.width * state.image.scale,
        height: state.image.image.height * state.image.scale,
        // Line below allows scrolling on mobile.
        // See: https://konvajs.org/docs/events/Mobile_Scrolling.html
        preventDefault: false,
      }));
      // Assuming re-rendering of the image on every change:
      // (otherwise use o.clearCache(); o.filters([]);)
      if (state.mosaic) {
        var objs = state.canvas.stage.find('Image');
        objs.map(o => {
            o.cache();
            o.filters([Konva.Filters.Pixelate]);
            o.pixelSize(state.grid.size);
        });
      }
    }
  },
  updateObjects: function (t, attr, val) {
    var objs = state.canvas.stage.find(t);
    objs.map(o => o.setAttr(attr, val));
  },

  renderGrid: function () {
    let lines = [];
    let labels = [];
    let width = state.canvas.width;
    let height = state.canvas.height;
    let linefunc, labelfunc = null;
    switch (state.grid.type) {
      case GridTypeEnum.POINTY_HEX:
        linefunc = GridLibrary.generatePointyHexGrid;
        labelfunc = GridLibrary.generatePointyHexGridLabels;
        break;
      case GridTypeEnum.FLAT_HEX:
        linefunc = GridLibrary.generateFlatHexGrid;
        labelfunc = GridLibrary.generateFlatHexGridLabels;
        break;
      case GridTypeEnum.SQUARE:
      default:
        linefunc = GridLibrary.generateSquareGrid;
        labelfunc = GridLibrary.generateSquareGridLabels;
        break;
    }
    if (state.grid.enabled) {
    lines = linefunc(0, 0, width, height, state.grid.size);
    }
    if(state.grid.labels)
    {
      labels = labelfunc(0, 0, width, height, state.grid.size);
    }
    lines.map(function (el) {
      state.canvas.gridLayer.add(new Konva.Line({
        points: [el.x1, el.y1, el.x2, el.y2],
        stroke: state.grid.color,
        strokeWidth: state.grid.strokeWidth,
        preventDefault: false,
      }));
    });
    labels.map(function (el) {
      state.canvas.gridLayer.add(new Konva.Text({
        x: el.x,
        y: el.y,
        text: el.label,
        fontSize: state.grid.size / 2,
        fontFamily: 'sans-serif',
        fill: state.grid.color,
        width: state.grid.size,
        align: 'center',
        preventDefault: false,
      }));
    });
  },
  download: function () {
    return new Promise((resolve, reject) => {
      // https://konvajs.org/docs/data_and_serialization/Stage_Data_URL.html
      // https://stackoverflow.com/a/15832662
      function downloadURI(uri, name) {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link = null;
      }
      var dataURL = state.canvas.stage.toDataURL();
      downloadURI(dataURL, state.image.title + "-with-grid.png");

      resolve(1);
    });
  },
  render: function () {
    this.clearByType('Image');
    this.clearByType('Line');
    this.clearByType('Text');
    this.renderImage();
    this.renderGrid();
    state.canvas.imageLayer.draw();
    state.canvas.gridLayer.draw();
  },
  resize: function (width, height) {
    state.canvas.width = width;
    state.canvas.height = height;
    state.canvas.stage.width(width);
    state.canvas.stage.height(height);
    document.getElementById('app-canvas').width = width;
    document.getElementById('app-canvas').height = height;
  },
});

document.addEventListener("DOMContentLoaded", function (event) {

  let controlsRoot = document.getElementById("app-controls");
  let canvasRoot = document.getElementById("app-canvas");

  let _spinner = "span.spinner-grow.spinner-grow-sm.mx-1[role=status][aria-hidden=true]";

  function ImageUploadComponent(state, actions) {
    return {
      view: function (vnode) {
        return m("div.mb-3", [
          m("input.form-control#image-input[type=file]", {
            oninput: function (e) {
              let img = new Image;
              img.src = URL.createObjectURL(e.target.files[0]);
              img.onload = function () {
                actions.setImage(img);
                actions.setTitle(e.target.files[0].name)
                actions.resize(img.width, img.height);
                actions.render();
              };
            },
          }),

          m(".form-text.text-white", 
            m.trust(feather.icons.lock.toSvg()), 
            " Works offline! Your data remains on your device."),
        ]);
      }
    }
  }

  function EnableGridComponent(state, actions) {
    return {
      view: function (vnode) {
        return m(".mb-3", [
          m(".form-check.form-switch", [
            m("input.form-check-input#enable-grid[type=checkbox][role=switch]", {
              checked: state.grid.enabled,
              onchange: function (e) {
                e.redraw = false; // allow the bootstrap animation to continue
                actions.setGridEnabled(e.target.checked);
                actions.render();
              }
            }),
            m("label.form-check-label[for=enable-grid]", "Show grid")
          ])]);
      }
    }
  }

  function ShowLabelsComponent(state, actions) {
    return {
      view: function (vnode) {
        return m(".mb-3", [
          m(".form-check.form-switch", [
            m("input.form-check-input#show-labels[type=checkbox][role=switch]", {
              checked: state.grid.labels,
              onchange: function (e) {
                e.redraw = false; // allow the bootstrap animation to continue
                actions.setLabelsEnabled(e.target.checked);
                actions.render();
              }
            }),
            m("label.form-check-label[for=show-labels]", "Show labels")
          ])]);
      }
    }
  }

  function GridTypeComponent(state, actions) {
    var busy = { square: false, pointyhex: false, flathex: false};
    var debounceTimeout = null;
    return {
      view: function (vnode) {
        return m(".my-3.text-center", [
          m(".btn-group[role=group]", [
            m("input.btn-check#gridTypeSquare[type=radio][name=gridType][autocomplete=off]", {
              value: GridTypeEnum.SQUARE,
              checked: state.grid.type == GridTypeEnum.SQUARE,
              onchange: function (e) {
                e.redraw = false;
                clearTimeout(debounceTimeout);
                busy.square = true;
                m.redraw();
                debounceTimeout = setTimeout(() => {
                actions.setGridType(GridTypeEnum.SQUARE);
                actions.render();
                  busy.square = false;
                  m.redraw();
                }, 500);
              }
            }),
            m("label.btn.btn-outline-light"+(state.grid.type != GridTypeEnum.SQUARE?".text-white":"")+"[for=gridTypeSquare]",
              busy.square ? [m(_spinner), m("br")] : [m.trust(feather.icons.square.toSvg()), m("br")],
              " Square"),

            m("input.btn-check#gridTypePointyHex[type=radio][name=gridType][autocomplete=off]", {
              value: GridTypeEnum.POINTY_HEX,
              checked: state.grid.type == GridTypeEnum.POINTY_HEX,
              onchange: function (e) {
                e.redraw = false;
                clearTimeout(debounceTimeout);
                busy.pointyhex = true;
                m.redraw();
                debounceTimeout = setTimeout(() => {
                actions.setGridType(GridTypeEnum.POINTY_HEX);
                actions.render();
                  busy.pointyhex = false;
                  m.redraw();
                }, 500);
              }
            }),
            m("label.btn.btn-outline-light"+(state.grid.type != GridTypeEnum.POINTY_HEX?".text-white":"")+"[for=gridTypePointyHex]",
            busy.pointyhex ? [m(_spinner), m("br")] : [m.trust(feather.icons.hexagon.toSvg()), m("br")],
            " Hex (pointy)"),

            m("input.btn-check#gridTypeFlatHex[type=radio][name=gridType][autocomplete=off]", {
              value: GridTypeEnum.FLAT_HEX,
              checked: state.grid.type == GridTypeEnum.FLAT_HEX,
              onchange: function (e) {
                e.redraw = false;
                clearTimeout(debounceTimeout);
                busy.flathex = true;
                m.redraw();
                debounceTimeout = setTimeout(() => {
                actions.setGridType(GridTypeEnum.FLAT_HEX);
                actions.render();
                  busy.flathex = false;
                  m.redraw();
                }, 500);
              }
            }),
            m("label.btn.btn-outline-light"+(state.grid.type != GridTypeEnum.FLAT_HEX?".text-white":"")+"#flathex[for=gridTypeFlatHex]", 
              busy.flathex ? [m(_spinner), m("br")] : [m.trust(feather.icons.hexagon.toSvg()), m("br")],
              " Hex (flat)")
          ])
        ]);
      }
    }
  }

  function GridSizeComponent(state, actions) {
    var busy = false;
    var debounceTimeout = null;
    return {
      view: function (vnode) {
        return m(".mb-1", [
          m("label[for=gridSizeSlider]", "Grid size:"),
          m("output", { name: "gridSizeOutput", id: "gridSizeOutput", for: "gridSizeSlider" },
            m("span.px-1", {}, state.grid.size + " pixel" + (state.grid.size>1?'s':''), busy ? m(_spinner) : '')),
          m("input.form-range#gridSizeSlider[type=range]", {
            min: 1,
            max: 200,
            value: state.grid.size,
            oninput: function (e) {
              e.redraw = false; // See: https://mtsknn.fi/blog/how-to-debounce-events-in-mithriljs/
              state.grid.size = Number(e.target.value);
              clearTimeout(debounceTimeout);
              busy = true;
              m.redraw();
              debounceTimeout = setTimeout(() => {
                actions.render();
                busy = false;
                m.redraw();
              }, 500);
            },
          }),
        ]);
      }
    }
  }

  function LineWidthComponent(state, actions) {
    var busy = false;
    var debounceTimeout = null;
    return {
      view: function (vnode) {
        return m(".mb-4", [
          m("label[for=lineWidthSlider]", "Line width:"),
          m("output", { name: "lineWidthOutput", id: "lineWidthOutput", for: "lineWidthSlider" },
            m("span.px-1", {}, state.grid.strokeWidth + " pixel" + (state.grid.strokeWidth>1?'s':''), busy ? m(_spinner) : '')),
          m("input.form-range#lineWidthSlider[type=range]", {
            min: 1,
            max: 10,
            value: state.grid.strokeWidth,
            oninput: function (e) {
              e.redraw = false; // See: https://mtsknn.fi/blog/how-to-debounce-events-in-mithriljs/
              state.grid.strokeWidth = Number(e.target.value);
              clearTimeout(debounceTimeout);
              busy = true;
              m.redraw();
              debounceTimeout = setTimeout(() => {
                actions.render();
                busy = false;
                m.redraw();
              }, 500);
            },
          })
        ]);
      }
    }
  }

  function GridColorComponent(state, actions) {
    var busy = false;
    var debounceTimeout = null;
    return {
      view: function (vnode) {
        return m(".input-group.mt-2", [
          m("label.input-group-text#gridColorPickerLabel[for=gridColorPicker]", "Grid color:"),
          m("input.form-control.form-control-color#gridColorPicker[type=color]", {
            value: state.grid.color,
            oninput: function (e) {
              e.redraw = false; // See: https://mtsknn.fi/blog/how-to-debounce-events-in-mithriljs/
              state.grid.color = e.target.value;
              clearTimeout(debounceTimeout);
              busy = true;
              //m.redraw(); // redraw current node
              debounceTimeout = setTimeout(() => {
                actions.updateObjects('Line', 'stroke', state.grid.color);
                actions.updateObjects('Text', 'fill', state.grid.color);
                actions.render();
                busy = false;
                //m.redraw();
              }, 100);
            }
          }),
        ]);
      }
    }
  }

  function EnableMosaicComponent(state, actions) {
    return {
      view: function (vnode) {
        return m(".mt-2.mb-4", [
          m(".form-check.form-switch", [
            m("input.form-check-input#enable-mosaic[type=checkbox][role=switch]", {
              checked: state.mosaic,
              disabled: state.grid.type != GridTypeEnum.SQUARE,
              onchange: function (e) {
                e.redraw = false; // allow the bootstrap animation to continue
                state.mosaic = e.target.checked;
                actions.render();
              }
            }),
            m("label.form-check-label[for=enable-mosaic]", "Mosaic (square only)")
          ])]);
      }
    }
  }

  function DownloadComponent(state, actions) {
    var busy = false;
    return {
      view: function (vnode) {
        return m("button.btn.btn-success.btn-lg", {
          disabled: busy,
          onclick: async function (e) {
            busy = true;
            setTimeout(() => {
              // UX: pretend to work
              actions.download().then(() => {
                setTimeout((vnode) => {
                  busy = false;
                  m.redraw();
                }, 3000, vnode);
              });
            }, 2000);
          }
        }, busy ? [m(_spinner), ' ', "Generating file..."] : [m.trust(feather.icons.download.toSvg()), " Download result"]
        );
      }
    }
  }

  function ControlsComponent(state, actions) {
    /* Problem: Initializing the components in the tree below causes them
       to stop refreshing and become unresponsive.
       Apparent fix: Initializing these components here first fixes that problem.
       Since the components are (mostly) stateless, this should not
       introduce further issues.
     */
    var imageUploadComponent = ImageUploadComponent(state, actions);
    var gridTypeComponent = GridTypeComponent(state, actions);
    var gridSizeComponent = GridSizeComponent(state, actions);
    var lineWidthComponent = LineWidthComponent(state, actions);
    var mosaicComponent = EnableMosaicComponent(state, actions);
    var downloadComponent = DownloadComponent(state, actions);
    return {
      view: function (vnode) {
        return m("form", {
          onsubmit: function (e) {
            e.preventDefault();
          }
        },
        [
          m(".row", [
            m(".col", [m(EnableGridComponent(state, actions))]),
            m(".col", [m(ShowLabelsComponent(state, actions))]),
          ]),
          m("fieldset.form-group", [
            m("legend.mt-2.fs-5.fw-bold", "Select your image"),
            m(imageUploadComponent),]),
          
          m("fieldset.form-group", [
            m("legend.mt-2.fs-5.fw-bold", "Customize the grid"),
            m(gridTypeComponent),
            m(gridSizeComponent),
            m(lineWidthComponent),
            m(GridColorComponent(state, actions)),
            m(mosaicComponent),
            m(downloadComponent),
          ])
        ]);
      }
    }
  }

  function CanvasComponent(state, actions) {
    return {
      oninit: function (vnode) {
        state.canvas.stage = new Konva.Stage({
          container: 'app-canvas',
          width: state.canvas.width,
          height: state.canvas.height,
        });
        state.canvas.imageLayer = new Konva.Layer();
        state.canvas.gridLayer = new Konva.Layer();
        state.canvas.stage.add(state.canvas.imageLayer);
        state.canvas.stage.add(state.canvas.gridLayer);

        actions.render();
      },
      view: function (vnode) {
        return;
      }
    }
  }

  const state   = State();
  const actions = Actions(state);

  m.mount(controlsRoot, ControlsComponent(state, actions));
  m.mount(canvasRoot, CanvasComponent(state, actions));

});
